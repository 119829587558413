import {cookieGet, cookieSet} from "../utility/_cookies";

const themeName = "theme-dark";

/**
 * theme switch checkbox in page header
 */
export function themeSwitch() {
    if (cookieGet({name: "theme"}) === "true") {
        document.documentElement.classList.add(themeName)
    }
    
    const btn = document.querySelector("#themeSwitch");
    btn.onchange = e => {
        document.documentElement.classList.toggle(themeName, btn.checked);
        cookieSet({
            name: "theme",
            value: btn.checked,
            days: 365,
        });
    }
}