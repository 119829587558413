/**
 * change language switch in page header
 */
export function changeLanguage() {
    const lang = document.querySelector(".header-language");
    if (!lang) return;

    lang.onclick = e => e.stopPropagation();

    const btn = lang.querySelector(".header-language-btn");
    const ul = lang.querySelector(".header-language-list");
    if (!btn || !ul) return;

    btn.onclick = e => {
        ul.hidden = !ul.hidden;
    }

    document.addEventListener("click", e => {
        ul.hidden = true;
    })
}