
export function cookieSet({name, value, days, path, domain, secure}) {
    if (navigator.cookieEnabled) { //czy ciasteczka są włączone
        const cookieName = encodeURIComponent(name);
        const cookieVal = encodeURIComponent(value);
        let cookieText = cookieName + "=" + cookieVal;

        if (typeof days === "number") {
            const data = new Date();
            data.setTime(data.getTime() + (days * 24*60*60*1000));
            cookieText += ";expires=" + data.toUTCString();
        }

        if (path) {
            cookieText += ";path=" + path;
        }
        if (domain) {
            cookieText += ";domain=" + domain;
        }
        if (secure) {
            cookieText += ";secure";
        }

        document.cookie = cookieText;
    }
}

export function cookieGet({name}) {
    if (document.cookie !== "") {
        const cookies = document.cookie.split(/; */);

        for (let cookie of cookies) {
            const [ cookieName, cookieVal ] = cookie.split("=");
            if (cookieName === decodeURIComponent(name)) {
                return decodeURIComponent(cookieVal);
            }
        }
    }
    return false;
}

export function cookieDelete({name, path}) {
    if (cookieGet(name) !== false) {
        const cookieName = encodeURIComponent(name);
        let cookieText = cookieName + "=;";
        if (path) {
            cookieText += "path=" + path;
        }
        cookieText += ";expires=Thu, 01 Jan 1970 00:00:00 GMT";
        document.cookie = cookieText;
    }
}

//dodanie do glownego obiektu PTWP powyzszej funkcjonalnosci
//dzieki temu mozna jej uzywac inline
window.PTWP = window.PTWP || {};
window.PTWP.cookies = {};
window.PTWP.cookies.set = cookieSet;
window.PTWP.cookies.get = cookieGet;
window.PTWP.cookies.delete = cookieDelete;
